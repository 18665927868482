import React from "react";
import TopBar from "../components/Topbar";
import SideBar from "../components/Sidebar";
import { Outlet } from "react-router-dom";

function RootLayout() {
  return (
    <React.Fragment>
      <TopBar />
      <SideBar />
      <div
        style={{
          paddingTop: "45px",
          paddingLeft: "20px",
          overflow: "hidden",
        }}
      >
        <Outlet />
      </div>
    </React.Fragment>
  );
}

export default RootLayout;
