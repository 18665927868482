//import logo from "./logo.svg";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom"; // extra dependency for routing
import RootLayout from "./routes/Root";

import HomePage from "./routes/HomeRoute";

import UsersPage, { action as userAction } from "./routes/M1_Users/UsersRoute";
import UserDetailPage from "./routes/M1_Users/UserDetailRoute";
import LoginPage, { action as authAction } from "./routes/M1_Users/LoginRoute";
import { action as logoutAction } from "./routes/M1_Users/LogoutRoute";
import { getAuthToken, isAuth, isAdmin } from "./context/authContext";
import FlashcardsPage from "./routes/M2_Flashcards/FlashcardsRoute";
import QuestionsPage from "./routes/M3_Questions/QuestionsRoute";
import LeitnerBoxPage from "./routes/M4_LeitnerBox/LeitnerBoxRoute";

import Error403Page from "./routes/errors/Error403Route";
import Error404Page from "./routes/errors/Error404Route";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error403Page />,
    id: "RootLayout",
    loader: getAuthToken,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "/login",
        element: <LoginPage />,
        action: authAction,
      },
      {
        path: "/users",
        element: <UsersPage />,
        loader: isAdmin,
        action: userAction,
      },
      {
        path: "/users/:userName",
        element: <UserDetailPage />,
        loader: isAdmin,
      },
      {
        path: "/flashcards",
        element: <FlashcardsPage />,
        loader: isAuth,
      },
      { path: "/questions", element: <QuestionsPage />, loader: isAuth },
      { path: "/leitnerbox", element: <LeitnerBoxPage />, loader: isAuth },
    ],
  },
  { path: "/logout", action: logoutAction },
  { path: "/error404", element: <Error404Page /> },
  { path: "/error403", element: <Error403Page /> },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

/*
  <header className="App-header">
    <img src={logo} className="App-logo" alt="logo" />
    <p>
      Welcome <code>Daniel & Nicole</code> to our new blank React App!
    </p>
    <a
      className="App-link"
      href="https://reactjs.org"
      target="_blank"
      rel="noopener noreferrer"
    >
      Learn React
    </a>
  </header>
*/
