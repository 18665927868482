import React from "react";
import { Link, useRouteLoaderData } from "react-router-dom";
import classes from "./Sidebar.module.css";

function SideBar() {
  const authToken = useRouteLoaderData("RootLayout");
  // Close the sidebar with the close button
  const closeSidebar = async () => {
    // Get the Sidebar
    var mySidebar = document.getElementById("mySidebar");
    // Get the DIV with overlay effect
    var overlayBg = document.getElementById("myOverlay");
    mySidebar.style.display = "none";
    overlayBg.style.display = "none";
  };

  return (
    <React.Fragment>
      <nav
        className={
          classes["w3-sidebar"] +
          " w3-bar-block w3-collapse w3-large w3-animate-left"
        }
        style={{ display: "none" }}
        id="mySidebar"
      >
        <button
          onClick={closeSidebar}
          className="w3-right w3-xlarge w3-padding-large w3-LeitnerClose w3-hover-LeitnerClose"
          //w3-hide-large
          title="Close Menu"
        >
          <i className="fa fa-remove"></i>
        </button>
        <h4 className="w3-bar-item">
          <b>Menu</b>
        </h4>
        {authToken && (
          <Link
            to="/users"
            className="w3-bar-item w3-button w3-hover-black"
            onClick={closeSidebar}
          >
            Users
          </Link>
        )}
        {authToken && (
          <>
            {" "}
            <Link
              to="/flashcards"
              className="w3-bar-item w3-button w3-hover-black"
              onClick={closeSidebar}
            >
              Flashcards
            </Link>
            <Link
              to="/questions"
              className="w3-bar-item w3-button w3-hover-black"
              onClick={closeSidebar}
            >
              Questions
            </Link>
            <Link
              to="/leitnerbox"
              className="w3-bar-item w3-button w3-hover-black"
              onClick={closeSidebar}
            >
              Leitnerbox
            </Link>
          </>
        )}
      </nav>

      <div
        className="w3-overlay w3-hide-large"
        onClick={closeSidebar}
        style={{ cursor: "pointer" }}
        title="close side menu"
        id="myOverlay"
      ></div>
    </React.Fragment>
  );
}

export default SideBar;
