import { redirect, useNavigate } from "react-router-dom";

export function getAuthToken() {
  const authToken = localStorage.getItem("authToken");
  return authToken;
}

export async function isAuth() {
  const authToken = localStorage.getItem("authToken");
  if (!authToken) {
    return redirect("/login");
  } else {
    console.log("Verifying Login...");
    const response = await fetch(
      "https://clorohe17d.execute-api.ap-southeast-1.amazonaws.com/prod/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization_token: authToken,
        },
        //body: JSON.stringify(authData),
      }
    );
    await response.json().then(function (value) {
      console.log(value);
      if (value.userType !== "Registered" && value.userType !== "Admin") {
        localStorage.removeItem("authToken");
        return redirect("/login");
      }
    });
    return true;
  }
}

export async function isAdmin() {
  const authToken = localStorage.getItem("authToken");
  if (!authToken) {
    return redirect("/login");
  } else {
    console.log("Verifying Admin...");
    const response = await fetch(
      "https://clorohe17d.execute-api.ap-southeast-1.amazonaws.com/prod/auth",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization_token: authToken,
        },
        //body: JSON.stringify(authData),
      }
    );
    await response.json().then(function (value) {
      console.log(value);
      if (value.userType !== "Admin") {
        useNavigate("/error403");
      }
    });
    return true;
  }
}
