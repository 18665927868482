import React from "react";
import { Link } from "react-router-dom";

function LeitnerBoxPage() {
  return (
    <React.Fragment>
      <h1>LeitnerBox Page</h1>
      <Link to=".." relative="route">
        Back to Home
      </Link>
    </React.Fragment>
  );
}

export default LeitnerBoxPage;
