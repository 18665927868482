import React from "react";
import { Link } from "react-router-dom";
import TopBar from "../../components/Topbar";
import SideBar from "../../components/Sidebar";

function Error403Page() {
  return (
    <React.Fragment>
      <TopBar />
      <SideBar />
      <div
        style={{
          paddingTop: "45px",
          paddingLeft: "20px",
          overflow: "hidden",
        }}
      >
        <h1>Error 403</h1>
        <p>Forbidden route, this user is not authorised.</p>
        <Link to="/" relative="route">
          Back to Home
        </Link>
      </div>
    </React.Fragment>
  );
}

export default Error403Page;
