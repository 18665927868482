import React from "react";
import { Link } from "react-router-dom";

function FlashcardsPage() {
  return (
    <React.Fragment>
      <h1>Flashcards Page</h1>
      <Link to=".." relative="route">
        Back to Home
      </Link>
    </React.Fragment>
  );
}

export default FlashcardsPage;
