import React, { useState, useEffect } from "react";
import { Form, Link, useNavigation } from "react-router-dom";

import classes from "./UsersForm.module.css";

function UsersForm() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  let [users, setUsers] = useState([]);
  let getUsers = async () => {
    let response = await fetch(
      "https://clorohe17d.execute-api.ap-southeast-1.amazonaws.com/prod/users"
    );
    let data = await response.json();
    setUsers(data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <React.Fragment>
      <h1>Users Page</h1>
      <Link to=".." relative="route">
        Back to Home
      </Link>
      <Form method="post" className={classes.form}>
        <table style={{ marginLeft: "auto", marginRight: "auto" }}>
          <tbody>
            <tr className="w3-show-small w3-hide-large w3-hide-medium">
              <td style={{ paddingRight: 15 }}>
                <label htmlFor="userNameMob">Username</label>
              </td>
              <td style={{ width: 220 }}>
                <input id="userNameMob" name="userName2" />
              </td>
            </tr>
            <tr className="w3-show-small w3-hide-large w3-hide-medium">
              <td style={{ paddingRight: 15 }}>
                <label htmlFor="passwordMob">Password</label>
              </td>
              <td style={{ width: 220 }}>
                <input id="passwordMob" type="password" name="password2" />
              </td>
            </tr>
            <tr>
              <td
                style={{ paddingRight: 15 }}
                className="w3-hide-small w3-show-large"
              >
                <label htmlFor="userName">Username</label>
              </td>
              <td
                style={{ width: 220 }}
                className="w3-hide-small w3-show-large"
              >
                <input id="userName" name="userName" />
              </td>
              <td style={{ width: 170, paddingLeft: 20, paddingRight: 15 }}>
                <label htmlFor="emailAddress">Email Address</label>
              </td>
              <td style={{ width: 300 }}>
                <input id="emailAddress" name="emailAddress" />
              </td>
            </tr>
            <tr>
              <td
                style={{ paddingRight: 15 }}
                className="w3-hide-small w3-show-large"
              >
                <label htmlFor="password">Password</label>
              </td>
              <td
                style={{ width: 220 }}
                className="w3-hide-small w3-show-large"
              >
                <input id="password" type="password" name="password" />
              </td>
              <td style={{ width: 170, paddingLeft: 20, paddingRight: 15 }}>
                <label htmlFor="mobileNo">Contact No</label>
              </td>
              <td style={{ width: 300 }}>
                <input id="mobileNo" name="mobileNo" />
              </td>
            </tr>
            <tr>
              <td className="w3-hide-small"></td>
              <td className="w3-hide-small"></td>
              <td
                style={{
                  width: 170,
                  paddingLeft: 20,
                  paddingRight: 15,
                  paddingTop: 20,
                }}
              >
                <label htmlFor="userType">Access Rights</label>
              </td>
              <td style={{ width: 300, paddingTop: 20 }}>
                <select id="userType" name="userType">
                  <option id="Registered">Registered</option>
                  <option id="Admin">Admin</option>
                </select>
                <button
                  disabled={isSubmitting}
                  className="w3-LeitnerButton"
                  style={{
                    float: "right",
                    borderRadius: 10,
                  }}
                >
                  {isSubmitting ? "Saving" : "Save"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </Form>
      <div className={classes.resultTable}>
        <table>
          <thead>
            <tr>
              <th />
              <th>Username</th>
              <th>Access Rights</th>
              <th>Email Address</th>
              <th>Contact Number</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.userName}>
                <td>
                  <button
                    className="w3-LeitnerDeleteButton w3-hover-orange"
                    onClick={async (event) => {
                      if (
                        window.confirm(
                          `Delete the user named "${user.userName}"?`
                        )
                      ) {
                        await fetch(
                          "https://clorohe17d.execute-api.ap-southeast-1.amazonaws.com/prod/user",
                          {
                            method: "DELETE",
                            headers: {
                              "Content-Type": "application/json",
                              username: user.userName,
                            },
                          }
                        ).then(window.location.reload());
                      } else {
                        event.preventDefault();
                      }
                    }}
                  >
                    <i className="fa fa-remove"></i>
                  </button>
                </td>
                <td>
                  <Link to={`/users/${user.userName}`}>{user.userName}</Link>
                </td>
                <td>{user.userType}</td>
                <td>{user.emailAddress}</td>
                <td>{user.mobileNo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default UsersForm;
