import React from "react";
import { Link, Form, useRouteLoaderData } from "react-router-dom";

function TopBar() {
  const authToken = useRouteLoaderData("RootLayout");
  // Toggle between showing and hiding the sidebar, and add overlay effect
  const openSidebar = async () => {
    // Get the Sidebar
    var mySidebar = document.getElementById("mySidebar");
    // Get the DIV with overlay effect
    var overlayBg = document.getElementById("myOverlay");
    if (mySidebar.style.display === "block") {
      mySidebar.style.display = "none";
      overlayBg.style.display = "none";
    } else {
      mySidebar.style.display = "block";
      overlayBg.style.display = "block";
    }
  };

  return (
    <div className="w3-top">
      <div className="w3-bar w3-theme w3-top w3-left-align w3-large">
        <button
          className="w3-bar-item w3-button w3-left w3-hover-black w3-LeitnerLarge"
          onClick={() => {
            openSidebar();
          }}
          style={{ backgroundColor: "rgb(12 74 110)" }}
        >
          <i className="fa fa-bars" />
        </button>
        <button
          className="w3-bar-item w3-button w3-right w3-hover-black w3-LeitnerLarge"
          onClick={() => {
            openSidebar();
          }}
          style={{ backgroundColor: "rgb(12 74 110)" }}
        >
          <i className="fa fa-bars" />
        </button>
        <Link
          to="/"
          className="w3-bar-item w3-button w3-hover-black"
          style={{ color: "gold", backgroundColor: "rgb(12 74 110)" }}
        >
          Leitner Learner
        </Link>
        {authToken ? (
          <Form action="/logout" method="post">
            <button className="w3-bar-item w3-button w3-hover-black w3-LeitnerLarge">
              Logout
            </button>
          </Form>
        ) : (
          <Link
            to="/login"
            className="w3-bar-item w3-button w3-hover-black w3-LeitnerLarge"
          >
            Login
          </Link>
        )}
      </div>
    </div>
  );
}

export default TopBar;
