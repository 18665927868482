import React from "react";

function HomePage() {
  return (
    <React.Fragment>
      <h1>Welcome to Leitner Learner!</h1>
      <p>
        This site is a <em>Work-In-Progress</em>
      </p>
    </React.Fragment>
  );
}

export default HomePage;
