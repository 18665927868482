import LoginForm from "./LoginForm";
import { redirect } from "react-router-dom";
//import { json, redirect } from "react-router-dom";

function LoginPage() {
  return <LoginForm />;
}

export default LoginPage;

export async function action({ request }) {
  const data = await request.formData();
  const authData = {
    userName: data.get("userName"),
    password: data.get("password"),
  };
  if (!String(authData.userName).trim() || !String(authData.password).trim()) {
    console.log("Mandatory Field(s) not set.");
    alert("Mandatory Field(s) not set.");
    return { message: "Please enter a valid Username and Password." };
  } else {
    const response = await fetch(
      "https://clorohe17d.execute-api.ap-southeast-1.amazonaws.com/prod/auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authData),
      }
    );
    if (response.status === 401 || response.status === 422) {
      console.log("Authentication Denied!");
      alert("Authentication Denied!");
      return response;
    } else if (!response.ok) {
      console.log("Could not authenticate user.");
      alert("Could not authenticate user.");
      return {
        message:
          "An unexpected error occurred during the previous login attempt.",
      };
    } else {
      const resData = await response.json();
      const authToken = resData.authToken;
      localStorage.setItem("authToken", authToken);
      console.log("Authentication Succeeded!");
      return redirect("/");
    }
  }
}
