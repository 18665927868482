import UsersForm from "./UsersForm";
import { redirect } from "react-router-dom";

function UsersPage() {
  return <UsersForm />;
}

export default UsersPage;

export async function action({ request }) {
  const data = await request.formData();
  const userData = {
    userName: data.get("userName") || data.get("userName2"),
    userType: data.get("userType"),
    password: data.get("password") || data.get("password2"),
    emailAddress: data.get("emailAddress"),
    mobileNo: data.get("mobileNo"),
  };
  if (
    !String(userData.userName).trim() ||
    !String(userData.password).trim() ||
    !String(userData.emailAddress).trim()
  ) {
    console.log("Mandatory Field(s) not set.");
    alert("Mandatory Field(s) not set.");
    return { message: "Please enter a valid Username, Password and Email." };
  } else {
    try {
      const response = await fetch(
        "https://clorohe17d.execute-api.ap-southeast-1.amazonaws.com/prod/user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      ).then(
        function (value) {
          /* code if successful */
          window.location.reload();
          console.log("User Saved!");
        },
        function (error) {
          /* code if some error */
          console.log("Could not save user.");
          alert("Could not save user.");
        }
      );
    } catch (err) {
      console.error(`[ACTION ERROR]: ${err}`);
    }

    return redirect("/users");
  }
}
