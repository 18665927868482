import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

function UserDetailPage() {
  const params = useParams();
  let [user, setUser] = useState({});
  let getUser = async () => {
    let response = await fetch(
      "https://clorohe17d.execute-api.ap-southeast-1.amazonaws.com/prod/user",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          username: params.userName,
        },
        //body: JSON.stringify(authData),
      }
    );
    let data = await response.json();
    setUser(data);
  };

  useEffect(() => {
    getUser();
  });

  return (
    <React.Fragment>
      <h1>
        User Details for <em>{user["userName"]}</em>
      </h1>
      <table>
        <tbody>
          <tr>
            <td>Access Rights</td>
            <td>{user["userType"]}</td>
          </tr>
          <tr>
            <td>Email Address</td>
            <td>{user["emailAddress"]}</td>
          </tr>
          <tr>
            <td>Contact Number</td>
            <td>{user["mobileNo"]}</td>
          </tr>
          <tr>
            <td>Password Hash</td>
            <td>{user["passwordHash"]}</td>
          </tr>
          <tr>
            <td>Salt</td>
            <td>{user["salt"]}</td>
          </tr>
        </tbody>
      </table>
      <Link to=".." relative="path">
        Back To Users List
      </Link>
      <div>&nbsp;</div>
      <Link to=".." relative="route">
        Back to Home
      </Link>
    </React.Fragment>
  );
}

export default UserDetailPage;
