import { Form, Link, useNavigation, useActionData } from "react-router-dom";

import classes from "./LoginForm.module.css";

function LoginForm() {
  const actionData = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  return (
    <>
      <Link to=".." relative="route">
        Back to Home
      </Link>
      <Form method="post" className={classes.form}>
        <p>
          <label htmlFor="userName">Username</label>
          <input id="userName" name="userName" required />
        </p>
        <p>
          <label htmlFor="image">Password</label>
          <input id="password" type="password" name="password" required />
        </p>
        <div className={classes.actions}>
          <button disabled={isSubmitting} className="w3-LeitnerButton">
            {isSubmitting ? "Logging in..." : "Login"}
          </button>
        </div>
        {actionData && actionData.message && <p>{actionData.message}</p>}
      </Form>
    </>
  );
}

export default LoginForm;
